@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.animate-scale {
  animation: scale 8s infinite;
}

.swiper-pagination-bullet {
  width: 16px; /* Increase width */
  height: 16px; /* Increase height */
  background: #ccc;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 16px; /* Match width */
  height: 16px; /* Match height */
  background: #23a999;
}
